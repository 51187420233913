import { ICreditCardType } from '@shared/types'
import moment from 'moment'

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})

export const inputMasks = {
  phoneNumberMask: [
    '(',
    /[0-9]/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  zipCodeMask: [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  creditCardMask: [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  cpfMask: [
    /[0-9]/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  cnpjMask: [
    /[0-9]/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  monthYearMask: [/[0-9]/, /\d/, '/', /\d/, /\d/],
}

export const cpfOrCnpj = (value: string) => {
  const valueFormatted = value.replaceAll('.', '')
  if (valueFormatted.length <= 12) {
    return inputMasks.cpfMask
  } else {
    return inputMasks.cnpjMask
  }
}

export const withoutMask = (value: string) => {
  if (value === undefined) return ''

  return value.replace(/[^\d]+/g, '')
}

export function phoneMask(v: string) {
  if (v) {
    v = v.replace(/\D/g, '')
    v = v.substr(0, 11)
    v = v.replace(/^(\d{2})(\d)/g, '($1) $2')
    v = v.replace(/(\d)(\d{4})$/, '$1-$2')
    return v
  }
  return ''
}

export function cpfMask(v: string) {
  if (v) {
    v = v.replace(/\D/g, '')
    v = v.substr(0, 11)
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    return v
  }
  return ''
}

export function cnpjMask(v: string) {
  if (v) {
    v = v.replace(/\D/g, '')
    v = v.substr(0, 14)
    v = v.replace(/^(\d{2})(\d)/, '$1.$2')
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
    v = v.replace(/(\d{4})(\d)/, '$1-$2')
    return v
  }
  return ''
}

export const leftPad = (value: any, totalWidth: any, paddingChar?: any) => {
  const length = totalWidth - value.toString().length + 1
  return Array(length).join(paddingChar || '0') + value
}

const defaultFormat = /(\d{1,4})/g
const cards: ICreditCardType[] = [
  {
    type: 'amex',
    pattern: /^3[47]/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    length: [15],
    cvcLength: [4],
    luhn: true,
  },
  {
    type: 'aura',
    pattern: /^((?!504175))^((?!5067))(^50[0-9])/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  },
  {
    type: 'diners',
    pattern: /^(36|38|30[0-5])/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
    length: [14],
    cvcLength: [3],
    luhn: true,
  },
  {
    type: 'discover',
    pattern: /^(6011|65|64[4-9]|622)/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  },
  {
    type: 'elo',
    pattern:
      /^401178|^401179|^431274|^438935|^451416|^457393|^457631|^457632|^504175|^627780|^636297|^636369|^636368|^(506699|5067[0-6]\d|50677[0-8])|^(50900\d|5090[1-9]\d|509[1-9]\d{2})|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])|^(65092[1-9]|65097[0-8])/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  },
  {
    type: 'hipercard',
    pattern: /^(384100|384140|384160|606282|637095|637568|60(?!11))/,
    format: defaultFormat,
    length: [14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true,
  },
  {
    type: 'jcb',
    pattern:
      /^(308[8-9]|309[0-3]|3094[0]{4}|309[6-9]|310[0-2]|311[2-9]|3120|315[8-9]|333[7-9]|334[0-9]|35)/,
    format: defaultFormat,
    length: [16, 19],
    cvcLength: [3],
    luhn: true,
  },
  {
    type: 'mastercard',
    pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  },
  {
    type: 'visa',
    pattern: /^4/,
    format: defaultFormat,
    length: [13, 16],
    cvcLength: [3],
    luhn: true,
  },
]

export const cardFromNumber = (num): ICreditCardType => {
  let card, p, i
  num = (num + '').replace(/\D/g, '')
  for (i = 0; i < cards.length; i++) {
    card = cards[i]
    p = card.pattern
    if (p.test(num)) {
      return card
    }
  }
}

export const calcInstallment = (installment, valueTotal) => {
  if (installment && valueTotal) {
    let installmentValue = valueTotal / installment.number
    if (installment && installment.interest > 0) {
      let interest = installment.interest / 10000
      installmentValue =
        (valueTotal * interest) /
        (1 - Math.pow(1 + interest, -installment.number))
    }
    return installmentValue
  }
  return 0
}

export const compareData = (month: string, year: string) => {
  const currentDate = moment().format('YYYY-MM-01')
  return (
    moment(`20${year}-${month}-01`)
      // .format("YYYY-MM-DD")
      .isAfter(currentDate)
  )
}
