import { useAppSelector } from "@app/hooks";
import store from "@app/store";
import { selectCartItems } from "@features/cart/cartSlice";
import { selectPurchaseType } from "@features/checkout/checkoutSlice";
import { selectShowAddress } from "@features/customerAddress/customerAddressSlice";
import { selectSelectedDelivery } from "@features/delivery/deliverySlice";
import api from "@services/api";

export async function submitPayment(
  paymentMethod: "pix" | "boleto" | "credit_card",
  brand: "pix" | "boleto" | "mastercard" | any,
  gateway: number = 5,
  encryptedData?: any,
  ip: any = "",
  address?: any,
  extra?: {
    identificationNumber?: string;
    identificationType?: string;
    installments?: string;
  }
): Promise<any> {
  const token = store.getState().app.storeToken;

  const purchaseType = selectPurchaseType(store.getState());
  const showUserAdress = selectShowAddress(store.getState());

  let mpCardToken = null;
  if (encryptedData && encryptedData.mpCardToken) {
    mpCardToken = encryptedData.mpCardToken;
  }

  const customerId = store.getState().customer.id;

  const addressId = store.getState().customerAddress?.selectedAddress?.id || 0;

  const notes = store.getState().notes.notes;
  // console.log(store.getState())
  const cartItems = store.getState().cart.items;
  const selectedDelivery = store.getState().delivery.selectedCarrier;

  let defaultPaymentUrl = "pay/finish";
  let subscriptionPaymentUrl = "pay/subscription";

  let paymentData: any = {
    method: paymentMethod,
    brand,
    gateway,
    ip: ip,
    installments: extra?.installments,
    ...encryptedData,
    mpCardToken,
    cartItems: cartItems.map((item) => ({
      id: item.productId,
      quantity: item.quantity,
    })),
    selectedDelivery,
    identificationNumber: extra?.identificationNumber,
    identificationType: extra?.identificationType,
  };

  if (paymentMethod === "pix") {
    paymentData = {
      method: paymentMethod,
      brand,
      cartItems: cartItems.map((item) => ({
        id: item.productId,
        quantity: item.quantity,
      })),
      selectedDelivery,
      gateway,
      ip: ip,
      ...encryptedData,
    };
  }

  if (paymentMethod === "boleto") {
    paymentData = {
      method: paymentMethod,
      brand,
      gateway,
      cartItems: cartItems.map((item) => ({
        id: item.productId,
        quantity: item.quantity,
      })),
      selectedDelivery,
      ip: ip,
      ...encryptedData,
    };
  }

  const { address: _, ...paymentDataRest } = paymentData;

  const payload = {
    customerId,
    addressId: !showUserAdress ? null : addressId,
    shippingId: 1,
    gateway,
    paymentData: {
      ...paymentDataRest,
    },
    address: paymentData.address,
    notes,
  };

  const urlToUse =
    purchaseType === "SUBSCRIPTION"
      ? subscriptionPaymentUrl
      : defaultPaymentUrl;

  const { data } = await api.post(urlToUse, payload, {
    headers: { "x-access-token": token },
  });

  return data;
}
