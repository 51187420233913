import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from '@app/store'
import { Coupon, ICart } from '@shared/types'
import { applyCoupon, removeCoupon } from '@features/coupon/couponAPI'
import { setDiscount } from '@features/cart/cartSlice'

export interface ICouponState {
  coupon: Coupon | undefined
  couponDiscountValue: number
  couponError: any
  couponStatus: 'idle' | 'loading' | 'failed'
}

const initialState: ICouponState = {
  coupon: undefined,
  couponDiscountValue: 0,
  couponError: '',
  couponStatus: 'idle',
}

export const applyCouponThunk = createAsyncThunk(
  'cart/applyCoupon',
  async (couponCode: string, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await applyCoupon(couponCode)
      dispatch(setDiscount(response.totalDiscount))
      return response
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const removeCouponThunk = createAsyncThunk(
  'cart/removeCoupon',
  async (_, { dispatch }) => {
    try {
      const response = await removeCoupon()

      dispatch(setDiscount(0))

      return response
    } catch (err) {
      return
    }
  }
)

export const checkForCoupon = createAsyncThunk(
  'cart/checkForCoupon',
  async (cart: ICart, { dispatch }) => {
    if (cart.coupon) {
      dispatch(setCouponError(''))
      dispatch(setCoupon(cart.coupon))
      if (cart.coupon.type === 'PERCENT') {
        dispatch(
          setDiscountValue(cart.totalPrice * (cart.coupon.value / 10000))
        )
        dispatch(setDiscount(cart.totalPrice * (cart.coupon.value / 10000)))
      } else {
        dispatch(setDiscountValue(cart.coupon.value))
        dispatch(setDiscount(cart.coupon.value))
      }
    } else {
      dispatch(setDiscountValue(0))
      dispatch(setDiscount(0))
      dispatch(setCoupon(undefined))
    }
    return
  }
)

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setCoupon: (state, action: PayloadAction<any>) => {
      state.coupon = action.payload
    },
    setDiscountValue: (state, action: PayloadAction<number>) => {
      state.couponDiscountValue = action.payload
    },
    setCouponError: (state, action: PayloadAction<string>) => {
      state.couponError = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applyCouponThunk.pending, (state) => {
        state.couponStatus = 'loading'
      })
      .addCase(
        applyCouponThunk.rejected,
        (state, actions: PayloadAction<any>) => {
          state.couponStatus = 'failed'
          state.couponError = actions.payload.errors[0]
        }
      )
      .addCase(applyCouponThunk.fulfilled, (state, actions) => {
        state.couponStatus = 'idle'
        const coupon = actions.payload

        state.couponError = ''
        state.coupon = coupon
        state.couponDiscountValue = coupon.value
      })

    builder
      .addCase(removeCouponThunk.pending, (state) => {
        state.couponStatus = 'loading'
      })
      .addCase(removeCouponThunk.rejected, (state) => {
        state.couponStatus = 'failed'
      })
      .addCase(removeCouponThunk.fulfilled, (state) => {
        state.couponStatus = 'idle'

        state.couponError = ''
        state.coupon = undefined
        state.couponDiscountValue = 0
      })
  },
})

export const { setCoupon, setDiscountValue, setCouponError } =
  couponSlice.actions

export const selectCoupon = (state: AppState) => state.coupon.coupon

export const selectCouponDiscountValue = (state: AppState) =>
  state.coupon.couponDiscountValue

export const selectCouponError = (state: AppState) => state.coupon.couponError

export const selectCouponState = (state: AppState) => state.coupon.couponStatus

export default couponSlice.reducer
