import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import * as fbq from "@lib/fpixel";
import * as ttq from "@lib/tiktokpixel";
import { useAppSelector } from "@app/hooks";
import {
  selectCartId,
  selectTotalPrice,
  selectCartItems,
} from "@features/cart/cartSlice";
import GoogleAdsGlobal from "@hooks/trackers/GoogleAdsGlobal";
import GoogleAdsGlobalConvertion from "@hooks/trackers/GoogleAdsGlobalConvertion";
import GoogleTagManager from "@hooks/trackers/GoogleTagManager";
import FacebookTracker from "@hooks/trackers/FacebookTracker";
import TikTokTracker from "@hooks/trackers/TikTokTracker";
import TaboolaTracker from "@hooks/trackers/TaboolaTracker";
import { selectPixels, selectFbPixels } from "@features/pixels/pixelsSlice";
import { selectCustomerStage } from "@features/customer/customerSlice";
import { selectCustomerAddressStage } from "@features/customerAddress/customerAddressSlice";
import {
  selectPaymentStage,
  selectPaymentSelected,
  selectOrderId,
} from "@features/payment/paymentSelectors";

const Pixel = () => {
  const customerAddressStage = useAppSelector(selectCustomerAddressStage);
  const paymentMethod = useAppSelector(selectPaymentSelected);
  const customerStage = useAppSelector(selectCustomerStage);
  const paymentStage = useAppSelector(selectPaymentStage);
  const totalPrice = useAppSelector(selectTotalPrice);
  const cartItems = useAppSelector(selectCartItems);
  const orderId = useAppSelector(selectOrderId);
  const pixels = useAppSelector(selectPixels);
  const cartId = useAppSelector(selectCartId);

  const router = useRouter();
  const query = router.query;
  const method = query.method;
  const path = router.pathname;
  const paymentData = JSON.parse(localStorage.getItem("paymentData"));
  const [initCheckout, setInitCheckout] = useState(false);
  const [addInfo, setAddInfo] = useState(false);
  const [addPaymentInfo, setAddPaymentInfo] = useState(false);
  const fbPixels = useAppSelector(selectFbPixels);
  const [initFB, setInitFB] = useState(false);
  const [initTTK, setInitTTK] = useState(false);

  const initFBPixel = () => {
    if (initCheckout && fbPixels.length > 0 && !initFB) {
      fbPixels.map((px: any) => {
        fbq.init(px.pixelId);
      });
      fbq.pageview();
      fbq.event("InitiateCheckout", {
        content_ids: [cartId],
        content_type: "product_group",
      });
      setInitFB(true);
    }
  };
  const initTtqPixel = () => {
    const ttqPixels = pixels.filter((px: any) => px.type === "tiktok");
    if (initCheckout && ttqPixels.length > 0 && !initTTK) {
      ttqPixels.map((px: any) => {
        ttq.init(px.pixelId);
        ttq.event("InitiateCheckout");
      });
      setInitTTK(true);
    }
  };

  useEffect(() => {
    initFBPixel();
    initTtqPixel();
  }, [initCheckout, fbPixels, pixels]);

  useEffect(() => {
    if (customerStage === "idle") {
      setInitCheckout(true);
    }
  }, [customerStage]);

  useEffect(() => {
    const initCheckout = () => {
      if (customerAddressStage === "selection") {
        setAddInfo(true);
      }
    };
    initCheckout();
  }, [customerAddressStage]);

  useEffect(() => {
    const updateStage = () => {
      const contents = cartItems.map((item: any) => {
        const it = {
          content_id: item.id,
          content_name: item.title,
          quantity: item.quantity,
          price: item.price,
        };
        return it;
      });
      const ttqOptions = {
        contents: contents,
        content_type: "product",
        value: totalPrice / 100,
        currency: "BRL",
      };
      const ttqPixels = pixels.filter((px: any) => px.type === "tiktok");
      if (paymentStage === "done") {
        setAddPaymentInfo(true);
      }
      if (paymentStage === "selection") {
        fbPixels.length > 0 &&
          fbq.event("AddPaymentInfo", {
            content_ids: [cartId],
            content_type: "product_group",
          });
        ttqPixels.length > 0 && ttq.event("AddPaymentInfo");
      }
      if (paymentStage === "pucharse") {
        if (method === "pix") {
          fbPixels.map((px: any) => {
            if (px.pix) {
              if (px.customPix) {
                fbq.singleCustomEvent(px.customEventPix, px.pixelId, {
                  content_ids: [cartId],
                  content_type: "product_group",
                  value: totalPrice / 100,
                  currency: "BRL",
                  payment_method: paymentMethod,
                  orderId,
                });
              } else {
                fbq.singleEvent("Purchase", px.pixelId, {
                  content_ids: [cartId],
                  content_type: "product_group",
                  value: totalPrice / 100,
                  currency: "BRL",
                  payment_method: paymentMethod,
                  orderId,
                });
              }
            }
          });
          const ttqIsPix = ttqPixels.some((px) => px.pix === true);
          if (ttqIsPix) {
            ttq.event("CompletePayment", ttqOptions);
          }
        } else if (method === "boleto") {
          fbPixels.map((px: any) => {
            if (px.billet) {
              if (px.custom) {
                fbq.singleCustomEvent(px.customEventName, px.pixelId, {
                  content_ids: [cartId],
                  content_type: "product_group",
                  value: totalPrice / 100,
                  currency: "BRL",
                  payment_method: paymentMethod,
                  orderId,
                });
              } else {
                fbq.singleEvent("Purchase", px.pixelId, {
                  content_ids: [cartId],
                  content_type: "product_group",
                  value: totalPrice / 100,
                  currency: "BRL",
                  payment_method: paymentMethod,
                  orderId,
                });
              }
            }
          });
          const ttqPixels = pixels.filter((px: any) => px.billet === true);
          if (ttqPixels.length > 0) {
            ttq.event("CompletePayment", ttqOptions);
          }
        } else {
          fbPixels.length > 0 &&
            fbq.event("Purchase", {
              content_ids: [cartId],
              content_type: "product_group",
              value: totalPrice / 100,
              currency: "BRL",
              payment_method: paymentMethod,
              orderId,
            });

          ttqPixels.length > 0 && ttq.event("CompletePayment", ttqOptions);
        }
      }
    };
    updateStage();
  }, [paymentStage, path]);

  return (
    <>
      <FacebookTracker key={`FBTDEFAULT`} />
      <TikTokTracker />
      {pixels &&
        pixels.map((px: any, index: number) => {
          if (px.type === "googleAds") {
            return (
              <div key={px.id}>
                <GoogleAdsGlobal key={`GAD-${px.id}`} pixel={px} />
                {path === "/approved" && (
                  <GoogleAdsGlobalConvertion
                    key={`GADC${px.id}`}
                    pixel={px}
                    datalayer={{
                      value: paymentData.amount,
                      method: "credit",
                    }}
                  />
                )}
                {path === "/pix" && px.pix && (
                  <GoogleAdsGlobalConvertion
                    key={`GADC${px.id}`}
                    pixel={px}
                    datalayer={{
                      value: paymentData.amount,
                      method: "pix",
                    }}
                  />
                )}
                {path === "/boleto" && (
                  <GoogleAdsGlobalConvertion
                    key={`GADC${px.id}`}
                    pixel={px}
                    datalayer={{ value: paymentData.amount, method: "billet" }}
                  />
                )}
              </div>
            );
          }

          if (px.type === "gtm") {
            return (
              <div key={px.id}>
                <GoogleTagManager key={`GDM${px.id}`} pixel={px} />
              </div>
            );
          }

          if (px.type === "taboola") {
            return (
              <div key={px.id}>
                <TaboolaTracker key={`TB${px.id}`} pixel={px} />
                {(path === "/approved" ||
                  (path === "/pix" && px.pix) ||
                  (path === "/boleto" && px.billet)) && (
                  <Script key={`TBP${px.id}`} id={`pucharse${px.id}`}>
                    {`_tfa.push({
                      notify: 'event',
                      name: 'make_purchase',
                      id: ${px.pixelId}})`}
                  </Script>
                )}
                {initCheckout && (
                  <Script key={`TBSC${px.id}`} id={`START-CHECKOUT${px.id}`}>
                    {`_tfa.push({notify: 'event', name: 'start_checkout', id: ${px.pixelId}});`}
                  </Script>
                )}
                {addInfo && (
                  <Script
                    key={`TBCR${px.id}`}
                    id={`complete_registration${px.id}`}
                  >
                    {`_tfa.push({notify: 'event', name: 'complete_registration', id: ${px.pixelId}});`}
                  </Script>
                )}
                {addPaymentInfo && (
                  <Script key={`TBPI${px.id}`} id={`payment_info${px.id}`}>
                    {`_tfa.push({notify: 'event', name: 'add_payment_info', id: ${px.pixelId}});`}
                  </Script>
                )}
              </div>
            );
          }
        })}
    </>
  );
};
export default Pixel;
