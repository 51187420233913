import React, { memo } from "react";
import Script from "next/script";

const add = ({ pixel }: any) => {
  return (
    <>
      <Script>
        {`gtag('config', "AW-${pixel.pixelId.replace("AW-", "")}");`}
      </Script>
    </>
  );
};

export default memo(add);
