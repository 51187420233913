import api from '@services/api'
import { IData } from '@shared/types'
// import Router from "next/router";

// export type RequestType = 'rp' | 'ml' | 'default'
export type RequestType = 'va' | 'pi' | 'default'

export async function fetchCheckoutData(
  storeToken: string,
  type: RequestType = 'default'
): Promise<IData> {
  if (type === 'va') {
    const { data: checkoutData }: { data: IData } = await api.post(
      'carts/product',
      {
        hash: storeToken,
        url: window.location.host,
      }
    )
    return checkoutData
  }

  if (type === 'pi') {
    const { data: checkoutData }: { data: IData } = await api.get(
      'carts/product',
      {
        headers: { authorization: `Bearer ${storeToken}` },
      }
    )
    return checkoutData
  }

  const { data: checkoutData }: { data: IData } = await api.get(
    'pay/checkout',
    {
      headers: { authorization: `Bearer ${storeToken}` },
    }
  )

  return checkoutData
}
