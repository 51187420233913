import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "@app/store";
import {
  fetchCustomer,
  createCustomer,
  IFetchCustomer,
  updateCustomer,
} from "./customerAPI";
import { ICustomer } from "@shared/types";
import {
  setAvailableAddresses,
  setCustomerAddressStage,
  setSelectedAddress,
} from "@features/customerAddress/customerAddressSlice";
import { phoneMask, withoutMask } from "utils";
import { increaseActiveStep } from "@features/checkout/checkoutSlice";
import { setPaymentStage } from "@features/payment/paymentSlice";
import { setCustomerShippingStage } from "@features/delivery/deliverySlice";

export interface CustomerState {
  id: number;
  name: string;
  email: string;
  document: string;
  phone: string;
  ip: string;
  status: "idle" | "loading" | "failed";
  stage: "idle" | "done" | "new" | "editing";
}

export interface IFindCostumerByEmail {
  email: string;
  showUserAdress: boolean;
  productType: string;
}

export interface ICreateCostumerWithEmail {
  payload: ICustomer;
  showUserAdress?: boolean;
  productType: string;
}

export const findCustomerByEmail = createAsyncThunk(
  "customer/findCustomer",
  async (
    { email, productType, showUserAdress }: IFindCostumerByEmail,
    { dispatch }
  ) => {
    const response = await fetchCustomer(email);

    dispatch(setCustomerEmail(email));

    if (response) {
      dispatch(increaseActiveStep());
      if (
        (response.addresses && response.addresses.length > 0) ||
        !showUserAdress
      ) {
        if (showUserAdress === false && showUserAdress !== undefined) {
          if (productType === "phisical") {
            dispatch(setCustomerShippingStage("selection"));
            return response;
          }
          dispatch(setPaymentStage("selection"));
          return response;
        }

        dispatch(setAvailableAddresses(response.addresses));
        dispatch(setCustomerAddressStage("selected"));
        dispatch(setSelectedAddress(0));
        return response;
      }

      if (!response.name || !response.document || !response.phone) {
        return response;
      }

      dispatch(setCustomerAddressStage("new"));
      return response;
    }

    return response;
  }
);

export const createCustomerByData = createAsyncThunk(
  "customer/createCustomer",
  async (
    { payload, productType, showUserAdress }: ICreateCostumerWithEmail,
    thunkAPI
  ) => {
    const data = {
      ...payload,
      document: withoutMask(payload.document),
      phone: withoutMask(payload.phone),
    };
    const response = await createCustomer(data);

    if (response) {
      if (!showUserAdress) {
        if (productType === "phisical") {
          thunkAPI.dispatch(setCustomerShippingStage("selection"));
        } else {
          thunkAPI.dispatch(setPaymentStage("selection"));
        }
      } else {
        thunkAPI.dispatch(setCustomerAddressStage("selection"));
      }
      thunkAPI.dispatch(increaseActiveStep());
    }

    return response;
  }
);

/* NOT BEING USED! LEAVE IT HERE */
export const updateCustomerByData = createAsyncThunk(
  "customer/updateCustomer",
  async (payload: IFetchCustomer, thunkAPI) => {
    const response = await updateCustomer(payload);

    return response;
  }
);

const initialState: CustomerState = {
  id: 0,
  name: "",
  email: "",
  document: "",
  phone: "",
  ip: "",
  status: "idle",
  stage: "idle",
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    toggleEditCustomer: (state) => {
      state.stage = "editing";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findCustomerByEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(findCustomerByEmail.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(findCustomerByEmail.fulfilled, (state, action) => {
        const customer = action.payload;

        if (!customer) {
          state.id = 0;
          state.name = "";
          state.document = "";
          state.phone = "";
          state.ip = "";

          state.stage = "new";
        } else {
          state.id = customer.id;
          state.name = customer.name;
          state.document = customer.document;
          state.email = customer.email;
          state.phone = phoneMask(customer.phone);
          state.ip = customer.ip;

          if (!state.name || !state.document || !state.phone) {
            state.stage = "new";
          } else {
            state.stage = "done";
          }
        }

        state.status = "idle";
      });

    builder
      .addCase(createCustomerByData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCustomerByData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createCustomerByData.fulfilled, (state, action) => {
        const customer = action.payload;

        state.id = customer.id;
        state.name = customer.name;
        state.document = customer.document;
        state.email = customer.email;
        state.phone = phoneMask(customer.phone);
        state.ip = customer.ip;

        state.stage = "done";
      });

    builder
      .addCase(updateCustomerByData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCustomerByData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateCustomerByData.fulfilled, (state, action) => {
        const customer = action.payload;

        state.id = customer.id;
        state.name = customer.name;
        state.document = customer.document;
        state.email = customer.email;
        state.phone = phoneMask(customer.phone);
        state.ip = customer.ip;

        state.stage = "done";
      });
  },
});

export const { setCustomerEmail, toggleEditCustomer } = customerSlice.actions;

export const selectCustomerStage = (state: AppState) => state.customer.stage;

export const selectCustomer = (state: AppState) => state.customer;

export default customerSlice.reducer;
