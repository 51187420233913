declare global {
  interface Window {
    ttq: any;
  }
}

export const init = (id) => {
  window.ttq.load(id);
};

export const pageview = () => {
  window.ttq.page();
};

export const event = (name, options = {}) => {
  window.ttq.track(name, options);
};
