import { AppState } from "@app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUpsellState {
  upsells: [] | any[];
}

const initialState: IUpsellState = {
  upsells: [],
};

export const upsellSlice = createSlice({
  name: "upsell",
  initialState,
  reducers: {
    setUpsells: (state, actions: PayloadAction<[] | any[]>) => {
      state.upsells = actions.payload;
    },
  },
});

export const { setUpsells } = upsellSlice.actions;

export const selectUpsells = (state: AppState) => state.upsell.upsells;

export default upsellSlice.reducer;
