import store from '@app/store'
import api from '@services/api'
import { Coupon } from '@shared/types'

export const applyCoupon = async (couponCode: string): Promise<Coupon> => {
  const cartId = store.getState().cart.cartId
  const storeId = store.getState().cart.store.id

  const { data } = await api.post('/coupons/validate', {
    couponCode: couponCode,
    cartId,
    storeId,
  })

  return data
}

export const removeCoupon = async (): Promise<any> => {
  const couponCode = store.getState().coupon.coupon.code
  const cartId = store.getState().cart.cartId
  const storeId = store.getState().cart.store.id

  const { data } = await api.post('/coupons/removeCoupon', {
    couponCode,
    storeId,
    cartId,
  })

  return data
}
