import { AppState } from '@app/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPixel } from '@shared/types'

export interface ICartState {
  pixels: IPixel[]
  fbPixels: IPixel[]
}

const initialState: ICartState = {
  pixels: [],
  fbPixels: [],
}

const pixelSlice = createSlice({
  name: 'pixel',
  initialState,
  reducers: {
    setPixelsData: (state, action: PayloadAction<IPixel[]>) => {
      state.pixels = action.payload
      state.fbPixels = action.payload.filter(
        (pixel) => pixel.type === 'facebook'
      )
    },
  },
})

export const { setPixelsData } = pixelSlice.actions

export const selectPixels = (state: AppState) => state.pixel.pixels
export const selectFbPixels = (state: AppState) => state.pixel.fbPixels

export default pixelSlice.reducer
