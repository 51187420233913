import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

export const cacheAdapter = setupCache({
  maxAge: 5 * 60 * 1000,
  readOnError: (error, request) => {
    return error.request.status >= 400 && error.request.status < 600;
  },
  exclude: { query: false }
})

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_PATH,
  adapter: cacheAdapter.adapter,
})

export default api
