import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "@app/store";
import { CartItem, IShippingItem } from "@shared/types";
import api from "@services/api";

export interface DeliveryState {
  selectedCarrier: IShippingItem | null;
  stage: "idle" | "selection" | "done" | "selected";
  availableItems: IShippingItem[] | null;
  needSelectAgain: boolean;
}

const initialState: DeliveryState = {
  selectedCarrier: null,
  stage: "idle",
  needSelectAgain: false,
  availableItems: null,
};

interface IGetAvaiableShippingItems {
  cartItems: CartItem[] | [];
  storeId: number;
  zipCode: string;
  storeToken: string;
}

export const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    setSelectedShipment: (state, actions: PayloadAction<IShippingItem>) => {
      state.selectedCarrier = actions.payload;
    },
    setCustomerShippingStage: (
      state,
      actions: PayloadAction<"idle" | "selection" | "done" | "selected">
    ) => {
      state.stage = actions.payload;
    },
    setNeedSelectAgain: (state, actions: PayloadAction<boolean>) => {
      state.needSelectAgain = actions.payload;
    },
    setAvailableShippingItems: (
      state,
      actions: PayloadAction<IShippingItem[]>
    ) => {
      state.availableItems = actions.payload;
    },
  },
});

export const {
  setSelectedShipment,
  setCustomerShippingStage,
  setNeedSelectAgain,
  setAvailableShippingItems,
} = deliverySlice.actions;

export const selectSelectedDelivery = (state: AppState) =>
  state.delivery.selectedCarrier;

export const selectCustomerShippingStage = (state: AppState) =>
  state.delivery.stage;

export const selectNeedSelectAgain = (state: AppState) =>
  state.delivery.needSelectAgain;

export const selectAvailableShippingItems = (state: AppState) =>
  state.delivery.availableItems;

export const getAvaiableShippingItems = createAsyncThunk(
  "shipping/getAvaiableShippingItems",
  async (
    { cartItems, storeId, zipCode, storeToken }: IGetAvaiableShippingItems,
    thunkAPI
  ) => {
    try {
      const response = await api.post<IShippingItem[]>(
        `/pay/shipping-rate/${storeId}`,
        {
          to: {
            postal_code: zipCode.replace(/(\d{5})(\d{3})/, "$1-$2"),
          },
          products: cartItems.map((item) => ({
            id: item.productId,
            quantity: item.quantity,
          })),
        },
        {
          headers: {
            "x-access-token": storeToken,
          },
        }
      );

      thunkAPI.dispatch(
        setAvailableShippingItems(
          response.data.filter(
            (item) =>
              item.error === null || item.error === "" || item.error.length == 0
          )
        )
      );

      return response.data[0];
    } catch (err) {}
  }
);

export default deliverySlice.reducer;
