import "../styles/globals.css";
import "react-credit-cards/es/styles-compiled.css";
import "swiper/css";
import "swiper/css/pagination";

import { Provider } from "react-redux";
import type { AppProps } from "next/app";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import store, { persistor } from "../app/store";
import { PersistGate } from "redux-persist/integration/react";

import { hotjar } from "react-hotjar";
import Pixel from "@components/Pixel";

const theme = extendTheme({
  fonts: {
    body: `'Poppins', sans-serif`,
  },
  colors: {
    cv: {
      white: "#FFFFFF", // BRANCO
      black: "#000000", // PRETO
      gray: "#7E7E7E", // CINZA
      light_gray: "#DCDCDC", // CINZA CLARO
      light_gray_alt: "#F9F9F9", // CINZA CLARO 2
      mid_gray: "#C8C6C6", // CINZA MÉDIO
      green: "#00C767", // VERDE
      dark_green: "#00B83E", // VERDE ESCURO
      bg_gray: "#F2F2F2", // CINZA FUNDO
      red: "#FF3232", // VERMELHO
      blue: "#077DEA", // Azul
    },
  },
  styles: {
    global: () => ({
      body: {
        bg: "#F2F2F2",
      },
    }),
  },
});

export default function MyApp({ Component, pageProps }: AppProps) {
  if (typeof window !== "undefined") {
    hotjar.initialize(2782243, 6);
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Pixel />

        <ChakraProvider resetCSS={true} theme={theme}>
          <Component {...pageProps} />
        </ChakraProvider>
      </PersistGate>
    </Provider>
  );
}
