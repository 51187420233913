import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '@app/store'

export interface IInfoState {
  logoURL: string
  bannerURL: string
  storeEmail: string
  storeWhatsapp: string
  supportPhone: string
  supportEmail: string
  useStoreLogo: boolean
  useRedirectLink: boolean
  storeURL: string
  headName: string
  tradeName: string
  iconURL: string
}

const initialState: IInfoState = {
  logoURL: '',
  bannerURL: '',
  storeEmail: '',
  storeWhatsapp: '',
  supportPhone: '',
  supportEmail: '',
  useStoreLogo: true,
  useRedirectLink: true,
  storeURL: '',
  headName: '',
  tradeName: '',
  iconURL: '',
}

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setLogoURL: (state, action: PayloadAction<string>) => {
      state.logoURL = action.payload
    },
    setBannerURL: (state, action: PayloadAction<string>) => {
      state.bannerURL = action.payload
    },
    setStoreEmail: (state, action: PayloadAction<string>) => {
      state.storeEmail = action.payload
    },
    setStoreWhatsapp: (state, action: PayloadAction<string>) => {
      state.storeWhatsapp = action.payload
    },
    setSupportPhone: (state, action: PayloadAction<string>) => {
      state.supportPhone = action.payload
    },
    setSupportEmail: (state, action: PayloadAction<string>) => {
      state.supportEmail = action.payload
    },
    setStoreURL: (state, action: PayloadAction<string>) => {
      state.storeURL = action.payload
    },
    disableUseLogoAndRedirect: (state) => {
      state.useStoreLogo = true
      state.useRedirectLink = true
    },
    setIconAndTradeName: (
      state,
      action: PayloadAction<{ iconURL: string; headName: string }>
    ) => {
      state.iconURL = action.payload.iconURL
      state.headName = action.payload.headName
    },
    setTradeName: (state, action: PayloadAction<string>) => {
      state.tradeName = action.payload
    },
  },
})

export const {
  setLogoURL,
  setBannerURL,
  setStoreEmail,
  setStoreWhatsapp,
  setSupportPhone,
  setSupportEmail,
  disableUseLogoAndRedirect,
  setStoreURL,
  setIconAndTradeName,
  setTradeName,
} = infoSlice.actions

export const selectLogoURL = (state: AppState) => {
  const apiBucket = process.env.NEXT_PUBLIC_API_BUCKET
  if (state.info.useStoreLogo) return state.info.logoURL
  return `${apiBucket}/assets/logo_checkout.png`
}

export const selectBannerURL = (state: AppState) => {
  const bannerURL = state.info.bannerURL
  const apiBucket = process.env.NEXT_PUBLIC_API_BUCKET

  if (bannerURL !== null && apiBucket) {
    return `${apiBucket}/${bannerURL}`
  }

  return undefined
}

export const selectStoreEmail = (state: AppState) => state.info.storeEmail

export const selectStoreWhatsapp = (state: AppState) => state.info.storeWhatsapp

export const selectSupportPhone = (state: AppState) => state.info.supportPhone

export const selectSupportEmail = (state: AppState) => state.info.supportEmail

export const selectRawStoreURL = (state: AppState) => state.info.storeURL

export const selectStoreURL = (state: AppState) => {
  if (state.info.storeURL) return `//${state.info.storeURL.replace('www.', '')}`
  return '#'
}

export const selectUseRedirect = (state: AppState) => state.info.useRedirectLink

export const selectHeadName = (state: AppState) => state.info.headName

export const selectTradeName = (state: AppState) => state.info.tradeName

export const selectIconURL = (state: AppState) => state.info.iconURL

export default infoSlice.reducer
