import { AppState } from "@app/store";
import { setStoreToken } from "@features/app/appSlice";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Router from "next/router";
import { hotjar } from "react-hotjar";
import { submitPayment } from "./paymentAPI";

export enum GatewayType {
  converteme = "converteme",
  mercadopago = "mercadopago",
}

export interface IPaymentState {
  gateway: GatewayType;
  paymentTypes: any[];
  isCreditCardActive: boolean;
  isBilletActive: boolean;
  isPixActive: boolean;
  creditCardDiscount: number;
  billetDiscount: number;
  pixDiscount: number;
  installments: number[];
  payments: any[];
  stage: "idle" | "selection" | "done" | "pucharse";
  status: "idle" | "loading" | "failed";
  paymentSelected: "idle" | "pix" | "boleto" | "credit_card";
  isCreditCardLinkActive: boolean;
  isBilletLinkActive: boolean;
  isPixLinkActive: boolean;
  creditCardLink: string;
  billetLink: string;
  pixLink: string;
  orderId: string;
}

export enum PaymentTypes {
  pix = "pix",
  billet = "billet",
  creditCard = "creditCard",
}

export interface ISubmitPayment {
  paymentMethod: "pix" | "boleto" | "credit_card";
  brand: "pix" | "boleto" | "mastercard" | any;
  card_holder_name?: string;
  card_number?: string;
  card_expiration_date?: string;
  card_cvv?: any;
  encryptedCard?: any;
  mpCardToken?: any;
  installments?: number;
  installmentsAmount: number;
  amount: number;
  discount: number;
  shipping: number;
  taxValue: number;
  totalAmount: number;
  gatewayId: number;
  paymentTypeId: number;
  extra?: {
    identificationNumber?: string;
    identificationType?: string;
    installments?: string;
  };
  address?: {
    zipCode: string;
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    complement: string;
  };
}

interface IRedirectLinks {
  isCreditCardLinkActive: boolean;
  isBilletLinkActive: boolean;
  isPixLinkActive: boolean;
  creditCardLink: string;
  billetLink: string;
  pixLink: string;
}

const initialState: IPaymentState = {
  gateway: GatewayType.converteme,
  paymentTypes: [],
  isCreditCardActive: true,
  isBilletActive: true,
  isPixActive: true,
  creditCardDiscount: 0,
  billetDiscount: 0,
  pixDiscount: 0,
  installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  stage: "idle",
  status: "idle",
  paymentSelected: "idle",
  payments: [],
  isCreditCardLinkActive: false,
  isBilletLinkActive: false,
  isPixLinkActive: false,
  creditCardLink: "/purchase",
  billetLink: "/purchase",
  pixLink: "/purchase",
  orderId: "",
};

const urlFinish = {
  pix: "/pix",
  billet: "/boleto",
  credit_card: "/cartao-credito",
};

export const submitPaymentThunk = createAsyncThunk(
  "payment/submitPayment",
  async (
    { paymentMethod, brand, gatewayId, ...creditCardData }: ISubmitPayment,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      hotjar.event("button-click");

      const { app } = getState() as AppState;

      const ip = app.ip;

      const response = await submitPayment(
        paymentMethod,
        brand,
        gatewayId,
        creditCardData,
        ip
      );
      dispatch(setPaymentStage("done"));
      dispatch(setStoreToken(""));
      dispatch(setPaymentSelected(paymentMethod));
      dispatch(setOrderId(response.transaction));

      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentStage: (
      state,
      action: PayloadAction<"idle" | "selection" | "done" | "pucharse">
    ) => {
      state.stage = action.payload;
    },
    setPaymentTypes: (state, actions: PayloadAction<any>) => {
      state.paymentTypes = actions.payload;
    },
    setPaymentSelected: (state, actions: PayloadAction<any>) => {
      state.paymentSelected = actions.payload;
    },
    updatePaymentStatus: (state, actions: PayloadAction<any>) => {
      state.status = actions.payload;
    },
    disableActivePayment: (state, actions: PayloadAction<PaymentTypes>) => {
      switch (actions.payload) {
        case PaymentTypes.pix:
          state.isPixActive = false;
          return;

        case PaymentTypes.billet:
          state.isBilletActive = false;
          return;

        case PaymentTypes.creditCard:
          state.isCreditCardActive = false;
          return;
      }
    },
    setPaymentDiscount: (
      state,
      action: PayloadAction<{ payment: PaymentTypes; discount: number }>
    ) => {
      const { payment, discount } = action.payload;

      switch (payment) {
        case PaymentTypes.pix:
          state.pixDiscount = discount;
          return;

        case PaymentTypes.billet:
          state.billetDiscount = discount;
          return;

        case PaymentTypes.creditCard:
          state.creditCardDiscount = discount;
          return;
      }
    },
    setRedirectLinks: (state, action: PayloadAction<IRedirectLinks>) => {
      const { payload } = action;
      if (payload.isCreditCardLinkActive) {
        state.isCreditCardLinkActive = payload.isCreditCardLinkActive;
        state.creditCardLink = payload.creditCardLink;
      }

      if (payload.isBilletLinkActive) {
        state.isBilletLinkActive = payload.isBilletLinkActive;
        state.billetLink = payload.billetLink;
      }

      if (payload.isPixLinkActive) {
        state.isPixLinkActive = payload.isPixLinkActive;
        state.pixLink = payload.pixLink;
      }
    },
    setPayments: (state, action: PayloadAction<any[]>) => {
      state.payments = action.payload;
    },
    setOrderId: (state, action: PayloadAction<string>) => {
      state.orderId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitPaymentThunk.pending, (state) => {
        state.status = "loading";

        return;
      })
      .addCase(submitPaymentThunk.rejected, (state, actions) => {
        state.status = "failed";

        return;
      })
      .addCase(submitPaymentThunk.fulfilled, (state, actions) => {
        state.stage = "pucharse";
        localStorage.paymentData = JSON.stringify(actions.payload);
        switch (state.paymentSelected) {
          case "boleto":
            const url = state.isBilletLinkActive
              ? state.billetLink
              : "/purchase";
            Router.push({
              pathname: url,
              query: { method: "boleto" },
            });
            break;
          case "credit_card":
            const urlCreditCard = state.isCreditCardLinkActive
              ? state.creditCardLink
              : "/purchase";
            Router.push({
              pathname: urlCreditCard,
              query: { method: "cartao" },
            });
            break;
          case "pix":
            const urlPix = state.isPixLinkActive ? state.pixLink : "/purchase";
            Router.push({
              pathname: urlPix,
              query: { method: "pix" },
            });
            break;
          default:
            break;
        }
        return;
      });
  },
});
export const {
  setPaymentTypes,
  disableActivePayment,
  setPaymentDiscount,
  setPaymentStage,
  setRedirectLinks,
  setPayments,
  setPaymentSelected,
  setOrderId,
  updatePaymentStatus,
} = paymentSlice.actions;

export const selectPaymentStage = (state: AppState) => state.payment.stage;

export default paymentSlice.reducer;
