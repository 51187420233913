import React, { memo } from "react";
import Script from "next/script";

const add = ({ pixel }: any) => {
  return (
    <>
      <Script id={`${pixel.pixelId}`} strategy="afterInteractive">
        {`window._tfa = window._tfa || [];
  window._tfa.push({notify: 'event', name: 'page_view', id: ${pixel.pixelId}});
  !function (t, f, a, x) {
         if (!document.getElementById(x)) {
            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
         }
  }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/unip/${pixel.pixelId}/tfa.js',
  'tb_tfa_script');`}
      </Script>
    </>
  );
};

export default memo(add);
