import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { fetchCheckoutData, RequestType } from "./appAPI";
import { setCartData } from "@features/cart/cartSlice";
import { setPixelsData } from "@features/pixels/pixelsSlice";
import { setSelectedShipment } from "@features/delivery/deliverySlice";
import {
  setCheckoutType,
  setPrimaryColor,
  toggleMounted,
  setPurchaseType,
} from "@features/checkout/checkoutSlice";
import {
  setLogoURL,
  setStoreEmail,
  setStoreURL,
  setStoreWhatsapp,
  disableUseLogoAndRedirect,
  setIconAndTradeName,
  setBannerURL,
  setSupportPhone,
  setSupportEmail,
} from "@features/info/infoSlice";
import { CartItem, ICheckoutHeaderBar, IData } from "@shared/types";
import {
  disableActivePayment,
  PaymentTypes,
  setPaymentDiscount,
  setRedirectLinks,
  setPayments,
} from "@features/payment/paymentSlice";
import { setUpsells } from "@features/upsell/upsellSlice";
import { setOrderbumps } from "@features/orderbump/orderbumpSlice";
import { setCoupon } from "@features/coupon/couponSlice";
import { setNotesInfo } from "@features/notes/notesSlice";
import { setHeaderData } from "@features/header/headerSlice";
import {
  setSocialproofs,
  setSocialproofsComments,
} from "@features/socialproof/socialproofSlice";
import { setShowAdress } from "@features/customerAddress/customerAddressSlice";

export interface IAppState {
  storeToken: string;
  ip: string;
  originalStoreToken: string;
}

const initialState: IAppState = {
  storeToken: "",
  ip: "",
  originalStoreToken: "",
};

export interface RequestInfo {
  storeToken: string;
  type: RequestType;
}

export const retrieveData = createAsyncThunk(
  "checkout/retrieveCheckoutData",
  async ({ storeToken, type }: RequestInfo, { dispatch }) => {
    const response = await fetchCheckoutData(storeToken, type);
    dispatch(setAppState(response));
    return response;
  }
);

export const setAppState = createAsyncThunk(
  "checkout/setCheckoutData",
  async (response: IData, { dispatch }) => {
    const cart = response.cart;
    const user = response.user;
    const product = cart.items[0].product;
    const payments = response?.payments;
    const custom = cart?.customizations;
    const checkoutType = response.checkoutType;
    dispatch(setPixelsData(product.pixels));

    if (cart.type === "digital") dispatch(setCheckoutType("info"));
    dispatch(
      setLogoURL(
        `${process.env.NEXT_PUBLIC_API_BUCKET}/${custom.logo ? custom.logo : user.store.logo
        }`
      )
    );

    switch (checkoutType) {
      case "RETRY":
        dispatch(setPurchaseType("RETRY"));
        break;
      case "SUBSCRIPTION":
        dispatch(setPurchaseType("SUBSCRIPTION"));
        break;
      default:
        dispatch(setPurchaseType("DEFAULT"));
        break;
    }

    if (cart.checkoutToken) {
      dispatch(setStoreToken(cart.checkoutToken));
    }

    if (response.token) {
      dispatch(setStoreToken(response.token));
    }

    dispatch(
      setIconAndTradeName({
        iconURL: `${process.env.NEXT_PUBLIC_API_BUCKET}/${custom.icone || response.cart.store.icon
          }`,
        headName: `Finalizar Compra - ${response.user.tradeName}`,
      })
    );

    if (product.customizations.banner !== null) {
      dispatch(setBannerURL(product.customizations.banner));
    } else {
      dispatch(setBannerURL(null));
    }

    if (product.customizations.showAddress !== undefined) {
      dispatch(setShowAdress(product.customizations.showAddress));
    }

    dispatch(
      setPrimaryColor(product.customizations?.primaryColor || "#009d43")
    );

    if (cart.store.checkoutNotes && cart.store.checkoutNotes.active) {
      dispatch(
        setNotesInfo({
          title: cart.store.checkoutNotes.title,
          description: cart.store.checkoutNotes.description,
        })
      );
    }
    dispatch(setCartData(cart));

    if (cart.items.length >= 1 && cart.items[0].product.placeId === 1) {
      dispatch(disableUseLogoAndRedirect());
    }

    dispatch(setStoreEmail(cart.store.serviceEmail));

    dispatch(setStoreWhatsapp(cart.store.whatsapp));

    dispatch(setStoreURL(cart.items[0].product.tradeUrl));

    dispatch(setPixelsData(response.pixels));
    dispatch(setSupportPhone(cart.items[0].product.supportPhone));

    dispatch(setSupportEmail(cart.items[0].product.supportEmail));

    dispatch(setPayments(payments));

    const upsellsOnCheckout: any = []; // TODO: Type checking
    const upsellsOnFinish: any = []; // TODO: Type checking

    if (
      typeof product !== "undefined" &&
      typeof product.upsells !== "undefined"
    ) {
      cart.items.length > 0 &&
        cart.items.forEach((i: CartItem) => {
          i.product.upsells &&
            i.product.upsells.forEach((upsell: any) => {
              if (upsell.moment === "CHECKOUT") {
                upsellsOnCheckout.push(upsell);
              } else if (upsell.moment === "FINISH") {
                upsellsOnFinish.push(upsell);
              } else {
                // TODO: ?????
              }
            });
        });
    }

    dispatch(setUpsells(upsellsOnCheckout));

    const orderbumpsOnCreditCard: any = [];
    const orderbumpsOnBillet: any = [];
    const orderbumpsOnPix: any = [];

    if (
      typeof product !== "undefined" &&
      typeof product.orderbumps !== "undefined"
    ) {
      cart.items.length > 0 &&
        cart.items.forEach((i: any) => {
          i.product.orderbumps &&
            i.product.orderbumps.forEach((orderbump: any) => {
              switch (orderbump.method) {
                case "ALL":
                  orderbumpsOnCreditCard.push(orderbump);
                  orderbumpsOnBillet.push(orderbump);
                  orderbumpsOnPix.push(orderbump);
                  return;
                case "CREDITCARD":
                  orderbumpsOnCreditCard.push(orderbump);
                  return;
                case "BILLET":
                  orderbumpsOnBillet.push(orderbump);
                  return;
                case "PIX":
                  orderbumpsOnPix.push(orderbump);
                  return;
              }
            });
        });
    }

    dispatch(
      setOrderbumps({ type: "creditCard", items: orderbumpsOnCreditCard })
    );

    dispatch(setOrderbumps({ type: "billet", items: orderbumpsOnBillet }));

    dispatch(setOrderbumps({ type: "pix", items: orderbumpsOnPix }));

    dispatch(setCoupon(cart.coupon));

    if (
      product.checkoutRedirectLinks &&
      product.checkoutRedirectLinks.length > 0
    ) {
      dispatch(setRedirectLinks(product.checkoutRedirectLinks[0]));
    }

    if (product.customizations) {
      const headerBar: ICheckoutHeaderBar = {
        isTimerActive: product.customizations.isTimerActive,
        timerValue: product.customizations.timerValueInMin,
        timerDescription: product.customizations.timerDescription,
        isTextActive: product.customizations.isTextActive,
        textValue: product.customizations.textValue,
        barColor: product.customizations.barColor,
      };
      dispatch(setHeaderData(headerBar));
    }

    const socialproofs = [];
    const socialproofComments = [];

    if (product && product.socialproofs && product.socialproofs.length > 0) {
      cart.items.forEach((c: CartItem) => {
        c.product.socialproofs &&
          c.product.socialproofs.forEach((socialproof: any) => {
            if (socialproof.checkoutSocialproof) {
              socialproofs.push(socialproof);
              socialproof.checkoutSocialproof.checkoutSocialproofComments.forEach(
                (comment: any) => {
                  socialproofComments.push(comment);
                }
              );
            }
          });
      });
    }

    dispatch(setSocialproofs(socialproofs));
    dispatch(setSocialproofsComments(socialproofComments));

    const activePaymentTypes = new Set();
    product.paymentTypes.forEach((item) => {
      const type = item.payment_type.alias.toLowerCase();


      switch (type) {
        case "pix":
          const paymentPixIsEnabled = item.active && checkoutType !== 'SUBSCRIPTION';
          if (paymentPixIsEnabled) {
            activePaymentTypes.add(type);
            dispatch(
              setPaymentDiscount({
                payment: PaymentTypes.pix,
                discount: item.value,
              })
            );
          } else {
            dispatch(disableActivePayment(PaymentTypes.pix));
          }
          break;

        case "boleto":
          const paymentBilletIsEnabled = item.active && checkoutType !== 'SUBSCRIPTION';
          if (paymentBilletIsEnabled) {
            activePaymentTypes.add(type);
            dispatch(
              setPaymentDiscount({
                payment: PaymentTypes.billet,
                discount: item.value,
              })
            );

          } else {
            dispatch(disableActivePayment(PaymentTypes.billet));
          }
          break;

        default:

          activePaymentTypes.add('creditCard');
          dispatch(
            setPaymentDiscount({
              payment: PaymentTypes.creditCard,
              discount: item.value,
            })
          );
          if (!item.active) {
            dispatch(disableActivePayment(PaymentTypes.creditCard));
          }

          break;
      }
    });

    dispatch(toggleMounted());
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setStoreToken: (state, action: PayloadAction<string>) => {
      state.storeToken = action.payload;
    },
    setOriginalToken: (state, action: PayloadAction<string>) => {
      state.originalStoreToken = action.payload;
    },
    setIp: (state, action: PayloadAction<string>) => {
      state.ip = action.payload;
    },
  },
});

export const selectStoreToken = (state: AppState) => state.app.storeToken;

export const selectOriginalStoreToken = (state: AppState) =>
  state.app.originalStoreToken;
export const { setStoreToken, setOriginalToken, setIp } = appSlice.actions;
export default appSlice.reducer;
