import api from "@services/api";
import store from "@app/store";

export type ZipCodeResponse = {
  cep: string;
  city?: string;
  service?: string;
  state?: string;
  localidade?: string;
  complemento?: string;
  uf?: string;
  logradouro?: string;
  bairro?: string;
};

export type ZipCodeResponseError = string;

export interface CreateAddressDTO {
  zipCode: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
  city: string;
  state: string;
}

export async function findZipCode(
  zipCode: string
): Promise<ZipCodeResponse | ZipCodeResponseError> {
  try {
    const { data }: { data: ZipCodeResponse | ZipCodeResponseError } =

      await api.get(`https://viacep.com.br/ws/${zipCode.replace(/[^0-9]/g, '')}/json/`);

    return data;
  } catch (error) {
    return error.message;
  }
}

export async function submitAddress(address: CreateAddressDTO) {
  const token = store.getState().app.storeToken;

  const { data: response }: { data: any } = await api.post(
    `pay/address`,
    address,
    { headers: { authorization: `Bearer ${token}` } }
  );

  return response;
}

export async function deleteAddress(id: number) {
  const token = store.getState().app.storeToken;

  const { data: response }: { data: boolean } = await api.delete(
    `pay/address/${id}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return response;
}
