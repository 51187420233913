import React, { memo } from "react";
import Script from "next/script";

const add = ({ pixel }: any) => {
  return (
    <>
      <Script id={`${pixel.pixelId}`} strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
          `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
          `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
          `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
          `})(window,document,'script','dataLayer','${pixel.pixelId}');`}
      </Script>
    </>
  );
};

export default memo(add);
