import { AppState } from "@app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOrderbumpState {
  creditCard: [] | any[];
  pix: [] | any[];
  billet: [] | any[];
}

const initialState: IOrderbumpState = {
  creditCard: [],
  pix: [],
  billet: [],
};

export const orderbumpSlice = createSlice({
  name: "orderbump",
  initialState,
  reducers: {
    setOrderbumps: (
      state,
      action: PayloadAction<{
        type: "creditCard" | "pix" | "billet";
        items: [] | any[];
      }>
    ) => {
      state[action.payload.type] = action.payload.items;
    },
  },
});

export const { setOrderbumps } = orderbumpSlice.actions;

export const selectCreditCardOrderbumps = (state: AppState) =>
  state.orderbump.creditCard;

export const selectPixOrderbumps = (state: AppState) => state.orderbump.pix;

export const selectBilletOrderbumps = (state: AppState) =>
  state.orderbump.billet;

export default orderbumpSlice.reducer;
