import { AppState } from "@app/store";

export const selectCreditCardDiscount = (state: AppState) =>
  state.payment.creditCardDiscount;

export const selectBilletDiscount = (state: AppState) =>
  state.payment.billetDiscount;

export const selectPixDiscount = (state: AppState) => state.payment.pixDiscount;

export const selectIsCreditCardActive = (state: AppState) =>
  state.payment.isCreditCardActive;

export const selectIsBilletActive = (state: AppState) =>
  state.payment.isBilletActive;

export const selectIsPixActive = (state: AppState) => state.payment.isPixActive;

export const selectInstallments = (state: AppState) =>
  state.payment.installments;

export const selectPaymentStage = (state: AppState) => state.payment.stage;

export const selectPaymentStatus = (state: AppState) => state.payment.status;

export const selectPayments = (state: AppState) => state.payment.payments;

export const selectPaymentSelected = (state: AppState) => state.payment.paymentSelected;

export const selectOrderId = (state: AppState) => state.payment.orderId;

export const selectUserPhone = (state: AppState) => state.customer.phone;

export const selectUserEmail = (state: AppState) => state.customer.email;

export const selectFilteredCartItens = (state: AppState) => {
  const items = state.cart.items;
  const filtered = items.map((value) => {
    return {
      title: value.product.title,
      image: value.product.image,
    };
  });
  return filtered;
}
