import store from "@app/store";
import api from "@services/api";
import { IAddress, ICustomer } from "@shared/types";

export interface IFetchCustomer extends ICustomer {
  id: number;
  ip: string;
  addresses: IAddress[] | [];
}

export async function fetchCustomer(email: string): Promise<IFetchCustomer> {
  const token = store.getState().app.storeToken;

  const { data: response }: { data: IFetchCustomer } = await api.get(`pay/customer`, {
    params: { email: email },
    headers: { "x-access-token": token },
  });

  return response;
}

export async function createCustomer(
  customer: ICustomer
): Promise<IFetchCustomer> {
  const token = store.getState().app.storeToken;

  const response = await api.post(`pay/customer`, customer, {
    headers: { "x-access-token": token },
  });

  return response.data;
}

/* NOT BEING USED! LEAVE IT HERE */
export async function updateCustomer(
  customer: IFetchCustomer
): Promise<IFetchCustomer> {
  const token = store.getState().app.storeToken;

  const response = await api.post(`pay/customer`, customer, {
    headers: { "x-access-token": token },
  });

  return response.data;
}
