declare global {
  interface Window {
    fbq: any;
  }
}
export const init = (id) => {
  window.fbq("init", id);
};
export const pageview = () => {
  window.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  window.fbq("track", name, options);
};

export const singleEvent = (name, pixelId, options = {}) => {
  window.fbq("trackSingle", pixelId, name, options);
};

export const customEvent = (name, options = {}) => {
  window.fbq("trackCustom", name, options);
};

export const singleCustomEvent = (name, pixelId, options = {}) => {
  window.fbq("trackSingleCustom", pixelId, name, options);
};
