import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "@app/store";

export interface INotesState {
  active: boolean;
  title: string;
  description: string;
  notes: string;
  stage: "idle" | "filling" | "done";
}

const initialState: INotesState = {
  active: false,
  title: "",
  description: "",
  notes: "",
  stage: "done",
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setNotesInfo: (
      state,
      action: PayloadAction<{ title: string; description: string }>
    ) => {
      state.active = true;
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
    setNotesStage: (
      state,
      action: PayloadAction<"idle" | "filling" | "done">
    ) => {
      state.stage = action.payload;
    },
    setNotesValue: (state, action: PayloadAction<string>) => {
      state.notes = action.payload;
      state.stage = "done";
    },
  },
});

export const selectIsNotesActive = (state: AppState) => state.notes.active;

export const selectNotesTitle = (state: AppState) => state.notes.title;

export const selectNotesDescription = (state: AppState) =>
  state.notes.description;

export const selectNotesValue = (state: AppState) => state.notes.notes;

export const selectNotesStage = (state: AppState) => state.notes.stage;

export const { setNotesInfo, setNotesStage, setNotesValue } =
  notesSlice.actions;

export default notesSlice.reducer;
