import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItem, ICart, Store } from "@shared/types";

import { AppState } from "@app/store";

import {
  addCartItem,
  decreaseCartItem,
  increaseCartItem,
  removeCartItem,
} from "./cartAPI";

import { v4 as uuidv4 } from "uuid";
import { createStandaloneToast } from "@chakra-ui/react";

const toast = createStandaloneToast();

export interface ICartState {
  countItens: number;
  cartId: number;
  items: CartItem[] | [];
  placeId: number;
  totalDiscount: number;
  totalPrice: number;
  originalTotalPrice: number;
  store: Store | undefined;
  cartUuid: string;
  type: string;
}

const initialState: ICartState = {
  countItens: 0,
  cartId: 0,
  items: [],
  placeId: 0,
  totalDiscount: 0,
  totalPrice: 0,
  originalTotalPrice: 0,
  store: undefined,
  cartUuid: uuidv4(),
  type: "",
};

export interface IUpdateCartItemPayload {
  cartId: number;
  productId: number;
  variantId: number;
  quantity: number;
}

export interface IAddCartItemPayload {
  cartId: number;
  productId: number;
  variantId: number;
  type: "upsell" | "orderbump";
  id: number;
}

export interface IRemoveCartItemPayload {
  cartId: number;
  productId: number;
  variantId: number;
}

export const increaseCartItemThunk = createAsyncThunk(
  "cart/increaseCartItemQuantity",
  async (
    { cartId, productId, variantId, quantity }: IUpdateCartItemPayload,
    { dispatch }
  ) => {
    const response = await increaseCartItem(
      cartId,
      productId,
      variantId,
      quantity
    );

    if (response) {
      dispatch(updateCartItem({ productId, variantId, type: "increase" }));
    }

    return response;
  }
);

export const decreaseCartItemThunk = createAsyncThunk(
  "cart/decreaseCartItemQuantity",
  async (
    { cartId, productId, variantId, quantity }: IUpdateCartItemPayload,
    { dispatch }
  ) => {
    const response = await decreaseCartItem(
      cartId,
      productId,
      variantId,
      quantity
    );

    if (response) {
      dispatch(updateCartItem({ productId, variantId, type: "decrease" }));
    }

    return response;
  }
);

export const addCartItemThunk = createAsyncThunk(
  "cart/addCartItem",
  async (
    { cartId, productId, id, type, variantId }: IAddCartItemPayload,
    { rejectWithValue }
  ) => {
    try {
      const response = await addCartItem(
        cartId,
        productId,
        variantId,
        type,
        id
      );

      return response;
    } catch (err) {
      toast({
        title: err.response.data.errors[0],
        position: "top-right",
        isClosable: true,
        status: "warning",
      });

      return rejectWithValue(err.response.data);
    }
  }
);

export const removeCartItemThunk = createAsyncThunk(
  "cart/removeCartItem",
  async (
    { cartId, productId, variantId }: IRemoveCartItemPayload,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await removeCartItem(cartId, productId, variantId);

      if (response) {
        dispatch(removeCartItemReducer({ productId, variantId }));
      }

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setDiscount: (state, actions: PayloadAction<number>) => {
      state.totalDiscount = actions.payload;
    },
    setCartData: (state, action: PayloadAction<ICart>) => {
      const cart = action.payload;

      state.countItens = cart.countItens;
      state.cartId = cart.id;
      state.originalTotalPrice = cart.totalPrice;
      state.totalPrice = cart.totalPrice;
      state.totalDiscount = cart.totalDiscount;
      state.store = cart.store;
      state.placeId = cart.placeId;
      state.items = cart.items;
      state.type = cart.type;
    },
    updateCartItem: (
      state,
      action: PayloadAction<{
        productId: number;
        variantId: number;
        type: "increase" | "decrease";
      }>
    ) => {
      const { productId, variantId, type } = action.payload;

      const item = state.items.find(
        (item: CartItem) =>
          item.productId === productId && item.variantId === variantId
      );

      const itemIndex = state.items.findIndex(
        (item: CartItem) =>
          item.productId === productId && item.variantId === variantId
      );

      let updatedItem: CartItem;

      if (type === "increase") {
        updatedItem = { ...item, quantity: item.quantity + 1 };
      } else {
        updatedItem = { ...item, quantity: item.quantity - 1 };
      }

      state.items[itemIndex] = updatedItem;
    },
    removeCartItemReducer: (
      state,
      action: PayloadAction<{
        productId: number;
        variantId: number;
      }>
    ) => {
      const { productId, variantId } = action.payload;

      const item = state.items.find(
        (item: CartItem) =>
          item.productId === productId && item.variantId === variantId
      );

      const arrWithoutItem = state.items.filter(
        (cartItem: CartItem) => cartItem !== item
      );

      state.items = [...arrWithoutItem];
    },
    setItems: (
      state,
      actions: PayloadAction<{ count: number; items: CartItem[] | [] }>
    ) => {
      state.countItens = actions.payload.count;
      state.items = actions.payload.items;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(increaseCartItemThunk.pending, (state) => {})
      .addCase(increaseCartItemThunk.rejected, (state) => {})
      .addCase(increaseCartItemThunk.fulfilled, (state, actions) => {
        const response = actions.payload;

        state.originalTotalPrice = response.totalPrice;
        state.totalPrice = response.totalPrice;
        state.countItens = response.countItens;
      });

    builder
      .addCase(decreaseCartItemThunk.pending, (state) => {})
      .addCase(decreaseCartItemThunk.rejected, (state) => {})
      .addCase(decreaseCartItemThunk.fulfilled, (state, actions) => {
        const response = actions.payload;

        state.originalTotalPrice = response.totalPrice;
        state.totalPrice = response.totalPrice;
        state.countItens = response.countItens;
      });

    builder
      .addCase(addCartItemThunk.pending, (state) => {})
      .addCase(addCartItemThunk.rejected, (state, actions) => {})
      .addCase(addCartItemThunk.fulfilled, (state, actions) => {
        const response = actions.payload;

        state.totalPrice = response.totalPrice;
        state.originalTotalPrice = response.totalPrice;
      });

    builder
      .addCase(removeCartItemThunk.pending, (state) => {})
      .addCase(removeCartItemThunk.rejected, (state) => {})
      .addCase(removeCartItemThunk.fulfilled, (state, actions) => {
        const response = actions.payload;

        state.totalPrice = response.totalPrice;
        state.originalTotalPrice = response.totalPrice;
      });
  },
});

export const {
  setCartData,
  updateCartItem,
  removeCartItemReducer,
  setDiscount,
  setItems,
} = cartSlice.actions;

export const selectCart = (state: AppState) => state.cart;

export const selectCartItems = (state: AppState) => state.cart.items;

export const selectTotalPrice = (state: AppState) => state.cart.totalPrice;

export const selectTotalDiscount = (state: AppState) =>
  state.cart.totalDiscount;

export const selectStore = (state: AppState) => state.cart.store;

export const selectCartId = (state: AppState) => state.cart.cartId;

export const selectCartUuid = (state: AppState) => state.cart.cartUuid;

export default cartSlice.reducer;
