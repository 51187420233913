import React, { memo } from "react";
import Script from "next/script";
import { useAppSelector } from "@app/hooks";
import { selectCart } from "@features/cart/cartSlice";

const add = ({ pixel, datalayer }: any) => {
  const cart = useAppSelector(selectCart);

  const pixelId = pixel.pixelId.replace("AW-", "");

  return (
    <>
      {cart && (
        <Script>
          {`gtag('event', 'conversion', {
            'send_to': 'AW-${pixelId}/${pixel.accessToken}',
            'value': ${datalayer.value / 100},
            'currency': 'BRL',
            'method':"${datalayer.method}",
            'transaction_id': "${cart.cartId}",
          });`}
        </Script>
      )}
    </>
  );
};

export default memo(add);
