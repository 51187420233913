import api from "@services/api";
import { ICart } from "@shared/types";

export const increaseCartItem = async (
  cartId: number,
  productId: number,
  variantId: number,
  quantity: number
): Promise<ICart> => {
  const { data } = await api.put(
    `carts/modify/${cartId}/?productId=${productId}&variantId=${variantId}&quant=${quantity}`
  );

  return data;
};

export const decreaseCartItem = async (
  cartId: number,
  productId: number,
  variantId: number,
  quantity: number
): Promise<ICart> => {
  const { data } = await api.put(
    `carts/modify/${cartId}/?productId=${productId}&variantId=${variantId}&quant=${quantity}`
  );

  return data;
};

export const addCartItem = async (
  cartId: number,
  productId: number,
  variantId: number | null,
  type: string,
  id: number
): Promise<ICart | any> => {
  const payload = {
    productId,
    variantId,
    type,
    id,
  };

  const { data } = await api.post(`carts/modify/${cartId}/`, payload);

  return data;
};

export const removeCartItem = async (
  cartId: number,
  productId: number,
  variantId: number
): Promise<ICart> => {
  const { data } = await api.delete(
    `carts/modify/${cartId}/${productId}/${variantId}`
  );

  return data;
};
