import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import counterReducer from "@features/counter/counterSlice";
import progressReducer from "@features/progress/progressSlice";
import checkoutReducer from "@features/checkout/checkoutSlice";
import customerReducer from "@features/customer/customerSlice";
import cartReducer from "@features/cart/cartSlice";
import customerAddressReducer from "@features/customerAddress/customerAddressSlice";
import deliveryReducer from "@features/delivery/deliverySlice";
import appReducer from "@features/app/appSlice";
import paymentReducer from "@features/payment/paymentSlice";
import couponReducer from "@features/coupon/couponSlice";
import infoReducer from "@features/info/infoSlice";
import upsellReducer from "@features/upsell/upsellSlice";
import orderbumpReducer from "@features/orderbump/orderbumpSlice";
import pixelsReducer from "@features/pixels/pixelsSlice";
import notesReducer from "@features/notes/notesSlice";
import headerReducer from "@features/header/headerSlice";
import socialproofReducer from "@features/socialproof/socialproofSlice";

import {
  persistStore,
  persistCombineReducers,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["app", "info"],
};

const persistedReducer = persistCombineReducers(persistConfig, {
  counter: counterReducer,
  app: appReducer,
  progress: progressReducer,
  checkout: checkoutReducer,
  customer: customerReducer,
  cart: cartReducer,
  customerAddress: customerAddressReducer,
  delivery: deliveryReducer,
  payment: paymentReducer,
  coupon: couponReducer,
  info: infoReducer,
  upsell: upsellReducer,
  orderbump: orderbumpReducer,
  pixel: pixelsReducer,
  notes: notesReducer,
  header: headerReducer,
  socialproof: socialproofReducer,
});

export function makeStore() {
  return configureStore({
    // devTools: false,
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
}

const store = makeStore();
export const persistor = persistStore(store);
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
