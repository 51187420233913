import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "@app/store";

export type ProgressStateStatus = "idle" | "filling" | "done" | "failed";

export interface ProgressState {
  customer: ProgressStateStatus;
  customerAddress: ProgressStateStatus;
  delivery: ProgressStateStatus;
  notes: ProgressStateStatus;
  payment: ProgressStateStatus;
  // currentState:
  //   | "idle"
  //   | "customer"
  //   | "customerAddress"
  //   | "delivery"
  //   | "notes"
  //   | "payment";
}

export type Progress = keyof ProgressState;

const initialState: ProgressState = {
  customer: "idle",
  customerAddress: "idle",
  delivery: "idle",
  notes: "idle",
  payment: "idle",
  // currentState: "idle",
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    updateProgress: (
      state,
      action: PayloadAction<{
        progress: Progress;
        status: ProgressStateStatus;
      }>
    ) => {
      state[action.payload.progress] = action.payload.status;
    },
  },
  extraReducers: {},
});

export const selectProgress = (state: AppState) => state.progress;

// export const selectCurrentState = (state: AppState) =>
//   state.progress.currentState;

export const selectCustomerProgress = (state: AppState) =>
  state.progress.customer;

export const selectCustomerAddressProgress = (state: AppState) =>
  state.progress.customerAddress;

export const selectDeliveryProgress = (state: AppState) =>
  state.progress.customerAddress;

export const selectNotesProgress = (state: AppState) =>
  state.progress.customerAddress;

export const selectPaymentProgress = (state: AppState) =>
  state.progress.customerAddress;

export default progressSlice.reducer;
