import { createSlice } from '@reduxjs/toolkit'

import { AppState } from '@app/store'

export interface SocialproofState {
  socialproofs: any[]
  socialproofsComments: any[]
}

const initialState: SocialproofState = {
  socialproofs: [],
  socialproofsComments: [],
}

export const socialproofSlice = createSlice({
  name: 'socialproof',
  initialState,
  reducers: {
    setSocialproofs: (state, action) => {
      state.socialproofs = action.payload
    },
    setSocialproofsComments: (state, action) => {
      state.socialproofsComments = action.payload
    },
  },
  extraReducers: {},
})

export const selectSocialproof = (state: AppState) =>
  state.socialproof.socialproofs

export const selectSocialproofsComments = (state: AppState) =>
  state.socialproof.socialproofsComments

export const { setSocialproofs, setSocialproofsComments } =
  socialproofSlice.actions

export default socialproofSlice.reducer
