import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "app/store";

export interface IHeaderState {
  isTimerActive: boolean;
  timerValue: number;
  timerDescription: string;
  isTextActive: boolean;
  textValue: string;
  barColor: string;
}

type IHeaderData = {
  isTimerActive: boolean;
  timerValue: number;
  timerDescription: string;
  isTextActive: boolean;
  textValue: string;
  barColor: string;
};

const initialState: IHeaderState = {
  isTimerActive: false,
  timerValue: 0,
  timerDescription: "",
  isTextActive: false,
  textValue: "",
  barColor: "#000",
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderData: (state, action: PayloadAction<IHeaderData>) => {
      const { payload } = action;

      state.isTextActive = payload.isTextActive;
      state.textValue = payload.textValue;

      state.isTimerActive = payload.isTimerActive;
      state.timerValue = payload.timerValue;
      state.timerDescription = payload.timerDescription;
      state.barColor = payload.barColor || "#000";
    },
  },
});

export const selectIsTimerActive = (state: AppState) =>
  state.header.isTimerActive;

export const selectTimerValue = (state: AppState) => state.header.timerValue;

export const selectTimerDescription = (state: AppState) =>
  state.header.timerDescription;

export const selectIsTextActive = (state: AppState) =>
  state.header.isTextActive;

export const selectTextValue = (state: AppState) => state.header.textValue;

export const selectBarColor = (state: AppState) => state.header.barColor;

export const { setHeaderData } = headerSlice.actions;

export default headerSlice.reducer;
