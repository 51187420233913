import { createSlice } from '@reduxjs/toolkit'

import { AppState } from '@app/store'

export interface CheckoutState {
  mounted: boolean
  activeStep: number
  primaryColor: string
  shouldSkipAddress: boolean
  checkoutType: 'default' | 'info'
  purchaseType: 'DEFAULT' | 'SUBSCRIPTION' | 'RETRY'
}

const initialState: CheckoutState = {
  mounted: false,
  activeStep: 0,
  primaryColor: '#00C767',
  shouldSkipAddress: false,
  checkoutType: 'info',
  purchaseType: 'DEFAULT',
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    toggleMounted: (state) => {
      state.mounted = true
    },
    increaseActiveStep: (state) => {
      state.activeStep = state.activeStep + 1
    },
    decreaseActiveStep: (state) => {
      state.activeStep = state.activeStep - 1
    },
    setPrimaryColor: (state, payload) => {
      state.primaryColor = payload.payload
    },
    setCheckoutType: (state, payload) => {
      state.checkoutType = payload.payload
    },
    setPurchaseType: (state, payload) => {
      state.purchaseType = payload.payload
    },
  },
})

export const selectMountedState = (state: AppState) => state.checkout.mounted

export const selectActiveStep = (state: AppState) => state.checkout.activeStep

export const selectPrimaryColor = (state: AppState) =>
  state.checkout.primaryColor

export const selectShouldSkipAddress = (state: AppState) =>
  state.checkout.shouldSkipAddress

export const selectCheckoutType = (state: AppState) =>
  state.checkout.checkoutType

export const selectPurchaseType = (state: AppState) =>
  state.checkout.purchaseType

export const {
  toggleMounted,
  increaseActiveStep,
  decreaseActiveStep,
  setPrimaryColor,
  setCheckoutType,
  setPurchaseType,
} = checkoutSlice.actions

export default checkoutSlice.reducer
