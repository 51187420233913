import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "@app/store";
import {
  CreateAddressDTO,
  deleteAddress,
  findZipCode,
  submitAddress,
  ZipCodeResponse,
  ZipCodeResponseError,
} from "./customerAddressAPI";
import { IAddress } from "@shared/types";

export interface ICustomerAddressState {
  zipCode: string;
  zipCodeStatus: "idle" | "loading" | "failed" | "invalid";
  street: string;
  number: string;
  neighborhood: string;
  showAdress: boolean;
  complement: string;
  city: string;
  state: string;
  availableAddresses: IAddress[] | [];
  selectedAddress: IAddress | undefined;
  stage: "idle" | "new" | "selection" | "done" | "selected";
  editingAddressIndex: number | null;
}

export const findZipCodeByValue = createAsyncThunk(
  "customerAddress/findZipcode",
  async (zipCode: string, { dispatch }) => {
    dispatch(setCEP(zipCode));

    const response = await findZipCode(zipCode);

    return response;
  }
);

export const createAddress = createAsyncThunk(
  "customerAddress/createAddress",
  async (address: CreateAddressDTO) => {
    const response = await submitAddress(address);

    return response;
  }
);

export const updateAddress = createAsyncThunk(
  "customerAddress/updateAddress",
  async (address: CreateAddressDTO, { dispatch, rejectWithValue }) => {
    try {
      const response = await submitAddress(address);

      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustomerAddress = createAsyncThunk(
  "customerAddress/deleteAddress",
  async (id: number) => {
    const response = await deleteAddress(id);

    if (!response) {
      return response;
    }
    return id;
  }
);

const initialState: ICustomerAddressState = {
  zipCode: "",
  zipCodeStatus: "idle",
  street: "",
  number: "",
  showAdress: true,
  neighborhood: "",
  complement: "",
  city: "",
  state: "",
  availableAddresses: [],
  selectedAddress: undefined,
  stage: "idle",
  editingAddressIndex: null,
};

export const customerAddressSlice = createSlice({
  name: "customerAddress",
  initialState,
  reducers: {
    setCEP: (state, actions: PayloadAction<string>) => {
      state.zipCode = actions.payload;
    },
    setAddress: (state, actions: PayloadAction<any>) => {
      const address = actions.payload;

      state.street = address.street;
      state.number = address.number;
      state.neighborhood = address.neighborhood;
      state.complement = address.complement;
    },
    setAllAddress: (state, actions: PayloadAction<any>) => {
      const allAddress = actions.payload;

      state.zipCode = allAddress.zipCode;
      state.street = allAddress.street;
      state.number = allAddress.number;
      state.neighborhood = allAddress.neighborhood;
      state.complement = allAddress.complement;
      state.city = allAddress.city;
      state.state = allAddress.state;
    },

    setAvailableAddresses: (state, actions: PayloadAction<IAddress[] | []>) => {
      const addresses = actions.payload;

      state.availableAddresses = addresses;
      if (addresses.length > 0) {
        state.selectedAddress = addresses[0];
      }
    },

    setShowAdress: (state, action: PayloadAction<boolean>) => {
      state.showAdress = action.payload;
    },

    setSelectedAddress: (state, actions: PayloadAction<number>) => {
      const address = state.availableAddresses.find(
        (_: IAddress, index: number) => index === actions.payload
      );

      state.selectedAddress = address;
    },
    setCustomerAddressStage: (
      state,
      actions: PayloadAction<"idle" | "new" | "selection" | "done" | "selected">
    ) => {
      state.stage = actions.payload;
    },
    setEditingAddressIndex: (state, action: PayloadAction<number | null>) => {
      state.editingAddressIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findZipCodeByValue.pending, (state) => {
        state.zipCodeStatus = "loading";
      })
      .addCase(findZipCodeByValue.rejected, (state) => {
        state.zipCodeStatus = "failed";
      })
      .addCase(findZipCodeByValue.fulfilled, (state, action) => {
        const data = action.payload as ZipCodeResponse | ZipCodeResponseError;

        if (typeof data !== "string") {
          state.zipCodeStatus = "idle";
          state.city = data.localidade;
          state.state = data.uf;
          state.street = data.logradouro;
          state.neighborhood = data.bairro;
        } else {
          state.zipCodeStatus = "invalid";
        }
      });

    builder
      .addCase(createAddress.pending, () => {})
      .addCase(createAddress.rejected, () => {})
      .addCase(createAddress.fulfilled, (state, actions) => {
        const newAddress = actions.payload;

        if (state.availableAddresses.length === 0) {
          state.selectedAddress = newAddress;
        }

        state.availableAddresses = [...state.availableAddresses, newAddress];

        state.stage = "selection";
      });

    builder
      .addCase(updateAddress.pending, () => {})
      .addCase(updateAddress.rejected, () => {})
      .addCase(updateAddress.fulfilled, (state, actions) => {
        const updatedAddress = actions.payload;

        state.availableAddresses[state.editingAddressIndex] = updatedAddress;

        if (updatedAddress.id === state.selectedAddress.id) {
          state.selectedAddress = actions.payload;
        }

        state.editingAddressIndex = null;

        state.city = "";
        state.zipCode = "";
        state.state = "";
      });

    builder
      .addCase(deleteCustomerAddress.pending, () => {})
      .addCase(deleteCustomerAddress.rejected, () => {
        // error happened :(
      })
      .addCase(deleteCustomerAddress.fulfilled, (state, actions) => {
        const id = actions.payload;

        const newAddresses = state.availableAddresses.filter(
          (address: IAddress) => address.id !== id
        );

        if (newAddresses.length === 0) {
          state.availableAddresses = [];
          state.stage = "new";
          state.selectedAddress = undefined;
          state.city = "";
          state.zipCode = "";
          state.state = "";
        } else {
          state.availableAddresses = newAddresses;
          state.selectedAddress = newAddresses[0];
        }
      });
  },
});

export const {
  setAddress,
  setAllAddress,
  setAvailableAddresses,
  setCEP,
  setShowAdress,
  setSelectedAddress,
  setCustomerAddressStage,
  setEditingAddressIndex,
} = customerAddressSlice.actions;

export const selectCustomerAddressStage = (state: AppState) =>
  state.customerAddress?.stage;

export const selectShowAddress = (state: AppState) =>
  state.customerAddress?.showAdress;

export const selectCustomerAddress = (state: AppState) => state.customerAddress;

export const selectZipcode = (state: AppState) => state.customerAddress.zipCode;

export const selectAvailableAddresses = (state: AppState) =>
  state.customerAddress.availableAddresses;

export const selectAllAddress = (state: AppState) => ({
  zipCode: state.customerAddress.zipCode,
  street: state.customerAddress.street,
  number: state.customerAddress.number,
  neighborhood: state.customerAddress.neighborhood,
  complement: state.customerAddress.complement,
  city: state.customerAddress.city,
  state: state.customerAddress.state,
});

export const selectSelectedAddress = (state: AppState) =>
  state.customerAddress.selectedAddress;

export const selectEditingAddressIndex = (state: AppState) =>
  state.customerAddress.editingAddressIndex;

export default customerAddressSlice.reducer;
